import React from 'react'
import Carousel from '../../../react4xp/components/Carousel'
import SectionHeader from '../../../react4xp/components/SectionHeader'
import ReviewCard from '../../../react4xp/components/ReviewCard'
import FeatureCard from '../../../react4xp/components/FeatureCard'

function CarouselPart({ preHeading, heading, subHeading, cards, strings }) {
  return <Carousel
    sectionHeader={
      <SectionHeader
        layout='vertical'
        labelText={preHeading}
        title={heading}
        subtitle={subHeading}
        strings={strings}
      />
    }
    slides={cards?.map(card => (card.type === 'review' ? (
      <ReviewCard
        color={card.color}
        href={card.itemUrl}
        reviewValue={card.item.score || 0}
        totalStars={5}
        reviewText={card.title || card.item?.title}
        imgSrc={card.item?.customerImage?.link}
        imgAlt={card.item?.customerImage?.alt}
        authorName={card.item?.customerName}
        authorTitle={(card.item?.customerTitle ? `${card.item?.customerTitle}${card.item?.customerCompany ? `, ${card.item?.customerCompany}` : ''}` : '')}
      />
    ) : (
      <FeatureCard
        color={card.color}
        href={card.itemUrl}
        isExternalUrl={card.isExternalUrl}
        imgSrc={card.item?.imgSrc?.link}
        imgSrcMedium={card.item?.imgSrcMedium?.link}
        imgAlt={card.item?.imgSrc?.alt}
        imgWidth={960}
        title={card.title || card.item.title}
      />
    )))}
  />
}

export default (props) => <CarouselPart {...props} />
